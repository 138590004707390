import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Global, Title, Header, SliceZone, ListingCard, Wrapper, Section, SEO } from "../components";
import website from '../../config/website';
import Newsletter from '../components/Newsletter/Newsletter';

const IndexWrapper = Global.withComponent('main');

class Index extends Component {
  render() {
    const {
      data: { posts, home, partners, last },
    } = this.props;
    const { data } = home;
    return (
      <Layout>
        <SEO
          title={`${website.title} Management de projet, AMOA, Conseil, Expertise`}
        />
        <Header light />
        <SliceZone allSlices={data.body} posts={posts} partners={partners} last={last} />
      </Layout>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    home: PropTypes.object.isRequired,
    last: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    home: prismicHome {
      data {
        title {
          html
        }

        body {
          ... on PrismicHomeBodyHero {
            slice_type
            id
            primary {
              maintitle {
                html
                text
              }
              subtitle {
                html
                text
              }
#              introduction {
#                html
#                text
#              }
              bgimg {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1960, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
#              illustration {
#                url
#                localFile {
#                  childImageSharp {
#                    fluid(maxWidth: 560, quality: 90) {
#                      ...GatsbyImageSharpFluid_withWebp
#                    }
#                  }
#                }
#              }
              bgcolor
              orientation
              theme
            }
          }
            ... on PrismicHomeBodyTextpage {
                slice_type
                id
                primary {
                    title1 {
                        html
                        text
                    }
                    text {
                        text
                        html
                    }
                    bgcolor
#                    padding
                }
            }
            ... on PrismicHomeBodyListeDOffres {
                slice_type
                id
                items {
                    offre {
                        id
                        uid
                        document {
                            data {
                                thumb {
                                    url
                                }
                                title {
                                    text
                                }
                            thumb {
                                min{
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 200, quality: 90) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                            }
                        }
                    }
                }
            }
#          ... on PrismicHomeBodyArticlepage {
#            slice_type
#            id
#            primary {
#              direction
#              title1 {
#                html
#                text
#              }
#              text {
#                html
#              }
#              linklabel
#              link {
#                url
#                target
#                link_type
#              }
#              image {
#                carre {
#                  localFile {
#                    childImageSharp {
#                      fluid(maxWidth: 500, maxHeight: 400, quality: 90) {
#                        ...GatsbyImageSharpFluid_withWebp
#                      }
#                    }
#                  }
#                }
#              }
#            }
#          }
#          ... on PrismicHomeBodyQuote {
#            slice_type
#            id
#            primary {
#              quote {
#                html
#                text
#              }
#              author
#            }
#          }
          #          ... on PrismicHomeBodyListeDePersonne {
          #            slice_type
          #            id
          #            primary {
          #              selectedtext {
          #                html
          #              }
          #            }
          #            items {
          #              person_selected {
          #                uid
          #                document {
          #                  uid
          #                  data {
          #                    image {
          #                      big {
          #                        localFile {
          #                          childImageSharp {
          #                            fluid(maxWidth: 200, quality: 90) {
          #                              ...GatsbyImageSharpFluid_withWebp
          #                            }
          #                          }
          #                        }
          #                      }
          #                    }
          #                    bio {
          #                      html
          #                    }
          #                    name
          #                  }
          #                }
          #              }
          #            }
          #          }
#          ... on PrismicHomeBodyListeDePartenaires {
#            slice_type
#            id
#            primary {
#              title {
#                html
#              }
#              intro {
#                html
#              }
#            }
#            items {
#              partners {
#                uid
#                document {
#                  uid
#                  data {
#                    name
#                    logo {
#                      url
#                      localFile {
#                        childImageSharp {
#                          fluid(maxWidth: 200, quality: 90) {
#                            ...GatsbyImageSharpFluid_withWebp
#                          }
#                        }
#                      }
#                      alt
#                    }
#                  }
#                }
#              }
#            }
#          }
          ... on PrismicHomeBodyBlogListe {
            slice_type
            id
            primary {
              title {
                html
                  text
              }
              intro {
                html
              }
              more
              bgcolor
            }
          }
          ... on PrismicHomeBodyBlogLast {
              id
              primary {
                  more
                  title {
                      html
                      text
                  }
                  bgcolor
              }
              slice_type
          }
          ... on PrismicHomeBodyImgbanner {
            slice_type
            id
            primary {
              bannertitle {
                text
                html
              }
              text {
                html
                text
              }
              label
              link {
                url
                target
                link_type
              }
              align
              theme
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1960, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          #          ... on PrismicHomeBodyVideopage {
          #            slice_type
          #            id
          #            primary {
          #              title {
          #                html
          #              }
          #              texte {
          #                html
          #              }
          #              video {
          #                html
          #              }
          #              bgcolor
          #            }
          #          }
        }
      }
    }

    #    social: allPrismicHeroLinksBodyLinkItem {
    #      edges {
    #        node {
    #          primary {
    #            label {
    #              text
    #            }
    #            link {
    #              url
    #            }
    #          }
    #        }
    #      }
    #    }
    posts: allPrismicPost(filter: { uid: { ne: "dummy" } }, limit: 3, skip: 2, sort: { fields: [first_publication_date], order: DESC }) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                id
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    last: allPrismicPost(sort: { fields: [first_publication_date], order: DESC }, filter: { uid: { ne: "dummy" } }, limit: 2) {
        edges {
            node {
                uid
                first_publication_date
                last_publication_date
                data {
                    title {
                        text
                    }
                    shortdesc {
                        text
                    }
                    main {
                        full {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1200, quality: 90) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    date(formatString: "DD.MM.YYYY")
                    categorie {
                        document {
                            uid
                            data {
                                name
                                color
                            }
                        }
                    }
                    authors {
                        author {
                            id
                            uid
                            document {
                                data {
                                    name
                                    image {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #    projects: allPrismicProjectsBodyLinkItem {
    #      edges {
    #        node {
    #          primary {
    #            label {
    #              text
    #            }
    #            link {
    #              url
    #            }
    #          }
    #        }
    #      }
    #    }
  }
`;
